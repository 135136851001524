import React, { useEffect, useState } from 'react'
import axios from 'axios'
import FormValidation from '../service/FormValidation'
import Select from 'react-select'
import Swal from 'sweetalert2'

const FormRegister = () => {
    const [propinsi, setPropinsi] = useState([]);
    const [kabupaten, setKabupaten] = useState([]);
    const [sekolah, setSekolah] = useState([])
    const [jenjang, setJenjangPen] = useState([]);
    const [kelasSemester, setKelasSemester] = useState([]);
    const [kelasSmtFilter, setKelasSmtFilter] = useState([]);
    const [konsultan, setKonsultan] = useState([]);
    const [recomender, setRecomender] = useState([]);
    const [renderSekolah, setRenderSekolah] = useState(false);
    const [renderKet, setRenderKet] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [data, setData] = useState({
        nama_lengkap:"",
        no_wa:"",
        email:"",
        bornDate:"",
        id_propinsi:"",
        id_kabkota:"",
        code_jenjang:"",
        code_kelas:"",
        code_consultant:"",
        id_sekolah:"",
        atribut:"",
        posisi_magang:"",
        document:"",
        link:"",
    })
    const [formError, setFormError] = useState({})
    let usia = [];
    for (let i = 10; i < 40; i++){
        usia.push(i)
    }
    const api = {
        url: "http://103.41.205.87:80/api/v1/application/", //dev
        url2: "http://103.41.205.87/", //dev
        url3: "https://development.niecindonesia.com/api/v1/application/", //prod
        url4: "https://development.niecindonesia.com/", //prod
        // url3: "https://superstudent.niecindonesia.com/api/v1/application/", //prod
        // url4: "https://superstudent.niecindonesia.com/", //prod
        key: 'koibg3kyOxX73kKTNwCsbNXnocy6nMPQ',
        eventKey: "eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ"
    };

    const handleChange = (e) => {
        const {name, value} = e.target
        setData({...data, [name]:value}) 
    }

    const handleSekolah = (id_propinsi) => {
        axios.get(api.url3 + 'sekolah-indonesia/' + id_propinsi)
        .then(res => setSekolah(res.data.data))
        .catch(err => console.log(err))
    }

    const loadKabKota = (e) => {
        const {name, value} = e.target
        handleSekolah(value)
        setData({...data, [name]: value})

        axios.get(api.url3 + 'master/kabupaten/' + value)
        .then(res => setKabupaten(res.data.data))
        .catch(err => console.log(err))
    }

    const loadConsultant = () => {
        axios.get(api.url3 + 'master/consultant/list-priotas', {
            headers: {
              Authorization: `Bearer ${api.key}`
            }
        })
        .then(response => response.data.data)
        .then(result => setKonsultan(result))
        .catch(error => console.log(error))
    }
    
    const filterConsultant = (e) => {
        const {name, value} = e.target
        setData({...data, [name]: value})

        axios.get(api.url3 + 'master/filter-mapping/' + value)
        .then(res => res.data.data)
        .then(result => {
            const dataKonsultan = Object.values(konsultan)
            const filterData = dataKonsultan.filter(dataFilter => {
                return dataFilter.id_area == result.id_area;
            });
            setRecomender(filterData);
        })
        .catch(err => console.log(err))
    }

    const setJenjang = (e) => {
        const {name, value} = e.target
        setData({...data, [name]:value})

        if (value === '') {
            setRenderKet(false)
            setRenderSekolah(false)  
        }
        else if (value === 'JP004') {
            setRenderKet(true)
            setRenderSekolah(false)
        } 
        else {
            const temp = Object.values(kelasSemester)
            const id = value
            const filterKlsSmtr = temp.filter(dataFilter => {
                return dataFilter.code_jenjang === id
            })
            setKelasSmtFilter(filterKlsSmtr)
            setRenderKet(false)
            setRenderSekolah(true)
        }
    }

    const submitForm = () => {
        setLoading(true);

        let formData = new FormData();
        formData.append('nama_lengkap', data.nama_lengkap) 
        formData.append('no_wa', data.no_wa) 
        formData.append('email', data.email) 
        formData.append('id_propinsi', data.id_propinsi) 
        formData.append('id_kabkota', data.id_kabkota) 
        formData.append('code_jenjang', data.code_jenjang) 
        formData.append('code_kelas', data.code_kelas) 
        formData.append('code_consultant', data.code_consultant) 
        formData.append('id_sekolah', data.id_sekolah) 
        formData.append('atribut', data.atribut) 
        formData.append('posisi_magang', data.posisi_magang) 
        formData.append('document', selectedFile) 
        formData.append('link', data.link)  

        axios({
            method: 'POST',
            url: api.url3 + 'intership/list',
            data: formData,
            headers: {
                Authorization: `Bearer ${api.key}`
            }
        })
        .then(res=>{
            Swal.fire({
                title: 'Terima Kasih Sudah Registrasi',
                icon: 'success',
                confirmButtonText: 'Ok'
              }).then((result)=>{
                if (result.isConfirmed) {
                    setLoading(false)
                    window.location.reload(false)
                }
            })  
        })
        .catch(err=>console.log(err))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        
        setFormError(FormValidation(data))
        setIsSubmit(true)
    }

    const handleChangeSekolah = (selectedOption) => {
        setData({...data, id_sekolah: selectedOption.kode_sekolah})
    }

    const handleChangeDocument = (e) => {
        if (e.target.files[0].name.split('.').pop() == 'zip') {
            setSelectedFile(e.target.files[0]);
            const {name, value} = e.target
            setData({...data, [name]:value}) 
        } else {
            Swal.fire({
                title: 'File Hanya Dalam Berbentuk Zip',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }
    }

    useEffect(()=>{
        if (Object.keys(formError).length === 0 && isSubmit) {
            submitForm();
        }
    },[formError])

    useEffect(()=>{
        getDataPropinsi()
        getDataJenjang()
        getKelasSemester()
        loadConsultant()
    },[])

    const getDataPropinsi = () => {
        axios.get(api.url3 + 'master/provinces')
        .then(res => setPropinsi(res.data.data))
        .catch(err => console.log(err))
    }

    const getDataJenjang = () => {
        axios.get(api.url3 + 'master/jenjang',{ 'headers': { Authorization: `Bearer ${api.key}` } })
        .then(res => setJenjangPen(res.data.data))
        .catch(err => console.log(err))
    }
    const getKelasSemester = () => {
        axios.get(api.url3 + 'master/kelas',{ 'headers': { Authorization: `Bearer ${api.key}` } })
        .then(res => setKelasSemester(res.data.data))
        .catch(err => console.log(err))
    }
        
    return (
        <>
            <form action="" onSubmit={handleSubmit}>
                <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-input">
                    <input name='nama_lengkap' value={data.name} className={`form-control ${formError.nama_lengkap && "is-invalid"}`} type="text" placeholder="Nama Lengkap" aria-label="default input example" onChange={handleChange}/>
                    { formError.nama_lengkap && (<div className="invalid-feedback mb-3">{formError.nama_lengkap}</div>)}
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-input">
                        <input name='email' value={data.email} className={`form-control ${formError.email && "is-invalid"}`} type="email" placeholder="Email" aria-label="default input example" onChange={handleChange}/>
                        { formError.email && (<div className="invalid-feedback mb-3">{formError.email}</div>)}
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-input">
                        <input name='no_wa' value={data.no_wa} className={`form-control ${formError.no_wa && "is-invalid"}`} type="number" placeholder="Telepon" aria-label="default input example" onChange={handleChange}/>
                        { formError.no_wa && (<div className="invalid-feedback mb-3">{formError.no_wa}</div>)}
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-input">
                        <select name='id_propinsi' value={data.id_propinsi} className={`form-select ${formError.id_propinsi && "is-invalid"}`} aria-label="Default select example" onChange={loadKabKota}>
                        <option value={''}>--Provinsi--</option>
                        {propinsi?.map(prov=>{
                            return(<option key={prov.id} value={prov.id}>{prov.province_name}</option>)
                        })}
                        </select>
                        { formError.id_propinsi && (<div className="invalid-feedback mb-3">{formError.id_propinsi}</div>)}
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-input">
                        <select name='id_kabkota' value={data.id_kabkota} className={`form-select ${formError.id_kabkota && "is-invalid"}`} aria-label="Default select example" onChange={filterConsultant}>
                        <option value={''}>--Kabupaten/Kota--</option>
                        {kabupaten?.map(kab=>{
                            return(<option key={kab.id} value={kab.id}>{kab.kabupaten_name}</option>)
                        })}
                        </select>
                        { formError.id_kabkota && (<div className="invalid-feedback mb-3">{formError.id_kabkota}</div>)}
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-input">
                        <select name='code_consultant' value={data.code_consultant} className={`form-select ${formError.code_consultant && "is-invalid"}`} aria-label="Default select example" onChange={handleChange}>
                        <option value={''}>--Recomender--</option>
                        {recomender?.map(consultant=>{
                            return(<option key={consultant.id} value={consultant.code}>{consultant.name}</option>)
                        })}
                        </select>
                        { formError.code_consultant && (<div className="invalid-feedback mb-3">{formError.code_consultant}</div>)}
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-input">
                        <select name='code_jenjang' value={data.code_jenjang} className={`form-select ${formError.code_jenjang && "is-invalid"}`} aria-label="Default select example" onChange={setJenjang}>
                        <option value={''}>--Jenjang Pendidikan--</option>
                        {jenjang?.map(jen=>{
                            return(<option key={jen.code_jenjang} value={jen.code_jenjang}>{jen.jenjang_pendidikan}</option>)
                        })}
                        </select>
                        { formError.code_jenjang && (<div className="invalid-feedback mb-3">{formError.code_jenjang}</div>)}
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-input">
                        <select name='posisi_magang' value={data.posisi_magang} className={`form-select ${formError.posisi_magang && "is-invalid"}`} aria-label="Default select example" onChange={handleChange}>
                            <option value={''}>--Posisi Magang--</option>
                            <option value={1}>Culinary </option>
                            <option value={2}>F&B Rotation </option>
                            <option value={3}>Room Division </option>
                        </select>
                        { formError.posisi_magang && (<div className="invalid-feedback mb-3">{formError.posisi_magang}</div>)}
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-input">
                    <input name='document' value={data.document} className={`form-control ${formError.document && "is-invalid"}`} type="file" placeholder="Document" aria-label="default input example" onChange={handleChangeDocument}/>
                    { formError.document && (<div className="invalid-feedback mb-3">{formError.document}</div>)}
                    <p><i>*upload seluruh berkas dalam satu dokumen (CV, passport, ijazah, transkrip nilai, sertifikat kerja) berbentuk *zip *max. 2MB</i></p>
                    </div>
                </div>
                { data.posisi_magang == 1 && <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-input">
                    <input name='link' value={data.link} className={`form-control ${formError.link && "is-invalid"}`} type="text" placeholder="Link" aria-label="default input example" onChange={handleChange}/>
                    { formError.link && (<div className="invalid-feedback mb-3">{formError.link}</div>)}
                    <p><i>*pastikan link dapat diakses publik</i></p>
                    </div>
                </div> }
                { renderSekolah && 
                (   <>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-input">
                            <select name='code_kelas' value={data.code_kelas} className={`form-select ${formError.code_kelas && "is-invalid"}`} aria-label="Default select example" onChange={handleChange}>
                            <option value={''}>--Kelas/Semester--</option>
                            {kelasSmtFilter?.map(kls=>{
                                return(<option key={kls.code} value={kls.code}>{kls.name}</option>)
                            })}
                            </select>
                            { formError.code_kelas && (<div className="invalid-feedback mb-3">{formError.code_kelas}</div>)}
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-input">
                            <Select
                            options={sekolah}
                            getOptionLabel={(options) => options['nama_sekolah']}
                            getOptionValue={(options) => options['kode_sekolah']}
                            name='id_sekolah' onChange={handleChangeSekolah} />
                        </div>
                    </div>
                    </>
                )}
                {
                    renderKet && 
                    (
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-input">
                            <input name='atribut' type="textarea" className={`form-select ${formError.ket_lainnya && "is-invalid"}`} placeholder='Keterangan' />
                            { formError.ket_lainnya && (<div className="invalid-feedback mb-3">{formError.ket_lainnya}</div>)}
                        </div>
                    </div>
                    )
                }
                </div>
                <button type='submit' className={`btn ${loading && 'disabled'}`}>{loading ? 'Submit...' : 'Submit'}</button>
            </form>
        </>
    )
}

export default FormRegister