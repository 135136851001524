import React from 'react'
import '../style.css'

const Header = () => {
  return (
  <>
    <section id='header'>
      <div className="hero">
        <div className="container">
          <h1>Magang Impian</h1>
          <h1>Di Amerika Serikat,</h1>
          <h1>Peluang Gaji Hingga <span style={{color: '#E31E28'}}>470 Juta Pertahun</span></h1>
          <a href='#register'><button type='button' className='btn mt-5'>Daftar Sekarang</button></a>
        </div>
      </div>
    </section>
  </>
  )
}

export default Header