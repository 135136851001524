import '../style.css'
const WhySection = () => {
    const timelineData = [
        { title: "Upload Berkas", date: "17 Feb 2025" },
        { title: "Seleksi Internal by NIEC", date: "19 Feb 2025" },
        { title: "Training by NIEC", date: "21 Feb 2025" },
        { title: "Final Interview", date: "28 Feb 2025" },
    ];

    return (
        <section id='why-section'>
            <div className="container">
                {/* <h3 className='why-title'>Alasan Mengapa Harus Ikutan J-1 USA</h3>
                <div className='container'>
                    <div className='row'>
                        <div className="col-lg-12 col-md-6 col-sm-12">
                            <ul>
                                <li className='margin-bottom'><span className='text-bold'>Pengalaman Kerja Internasional:</span> Magang di Amerika Serikat memberikan kesempatan langka untuk mendapatkan pengalaman kerja internasional. Ini memungkinkan peserta magang untuk bekerja dalam lingkungan bisnis global.</li>
                                <li className='margin-bottom'><span className='text-bold'>Peningkatan Keterampilan dan Pengetahuan:</span> Peserta magang dapat mengembangkan keterampilan teknis dan soft skills yang berkualitas international.</li>
                                <li className='margin-bottom'><span className='text-bold'>Jaringan dan Kontak Profesional:</span> Mereka dapat berinteraksi dengan rekan-rekan kerja, mentor, dan profesional lainnya dalam industri mereka.</li>
                                <li className='margin-bottom'><span className='text-bold'>Peluang Karir:</span> Peluang karir yang mungkin terbuka setelah magang selesai. Peserta magang yang berkinerja baik sering memiliki peluang untuk mendapatkan pekerjaan tetap atau kontrak jangka panjang setelah magang mereka selesai.</li>
                            </ul>  
                        </div>
                    </div>
                </div> */}
                <h3 className='why-title'>Persyaratan</h3>
                <div className='container'>
                    <div className='row'>
                        <div className="col-lg-12 col-md-6 col-sm-12">
                            <ul>
                                <li>Usia 18 - 34 Tahun</li>
                                <li>Kemampuan berbicara dalam bahasa Inggris (Berbicara bahasa Inggris secara aktif)</li>
                                <li>Bagi pemegang Visa J1 sebelumnya, harus tinggal di Indonesia selama 2 tahun sebelum mengajukan Visa J1 untuk kedua kalinya.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <h3 className='why-title'>Posisi Magang</h3>
                <div className='container'>
                    <div className='row'>
                        <div className="col-lg-12 col-md-6 col-sm-12">
                            <ul>
                                <li>Jurusan Culinary</li>
                                <li>F&B Rotation</li>
                                <li>Room Division</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <span className="text-bold">Salary : $14,81 - $19/ jam atau $29.184/tahun sekitar Rp.474.969.600/ tahun</span>
                <h3 className='why-title'>Timeline</h3>
                <div className='container'>
                    <div className='row'>
                        <div className="col-lg-12 col-md-6 col-sm-12">
                            <div className="timeline-container">
                                {timelineData.map((item, index) => (
                                    <div key={index} className="timeline-card">
                                    <div className="header">
                                        <i className="fas fa-users"></i> <div className='timeline-title'>{item.title}</div>
                                    </div>
                                    <div className="date">{item.date}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhySection