import React, { useState } from 'react'
import '../navbar/navbar.css'
import Headroom from 'react-headroom'

const Navbar = () => {
    const [clicked, setClicked] = useState(false)

    const handleClick = () => {
        setClicked(!clicked)
    }
    
    const handleClickMenu = () => {
        setClicked(!clicked)
    }
    
  return (
    <Headroom>
        <div className='nav'>
            <a href="/">
                {/* <img src="/img/logo.png" alt="" /> */}
            </a>
            <div>
                <ul className={`navbar ${clicked ? '' : 'active'}`}>
                    <li><a onClick={handleClickMenu} href='#header'>Home</a></li>
                    <li><a onClick={handleClickMenu} href='#why-section'>Persyaratan</a></li>
                    <li><a onClick={handleClickMenu} href='#about-us'>Apa Itu J1 Visa</a></li>
                    <li><a className='reg' onClick={handleClickMenu} href='#register'>Register Sekarang</a></li>
                </ul>
            </div>
            <div className="hamburger" onClick={handleClick}>
                <i className={`fa-solid ${clicked ? 'fa-xmark' : 'fa-bars'}`}></i>
            </div>
        </div>
    </Headroom>
  )
}

export default Navbar